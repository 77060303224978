








import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import makeSelectOptions from "@common/util/makeSelectOptions";
import { roles } from "@/constants/user";
import appService from "@/services/appService";
import BlurredTenant from '@/components/BlurredTenant.vue';

const assignableRoleMap = (roleKeys?: string[]) => {
  const userInfo = userManager.getUserInfo();
  const activeTenant = appService.getActiveTenant();
  if (!roleKeys) {
    roleKeys = [];
    if (userInfo) {
      switch (userInfo.role) {
        case "systemAdmin":
        case "admin":
          if (activeTenant) {
            roleKeys.push("cpoAdmin", "cpoMonitor", "retailer", "user");
          } else {
            roleKeys.push("systemAdmin");
          }
          break;
        case "cpoAdmin":
          roleKeys.push("cpoAdmin", "cpoMonitor", "retailer", "user");
          break;
      }
    }
  }
  const roleMap = {};
  for (const k of roleKeys) {
    roleMap[k] = roles[k];
  }
  return roleMap;
};

const filterableRoleMap = () => {
  const userInfo = userManager.getUserInfo();
  const activeTenant = appService.getActiveTenant();
  const roleKeys = [];
  if (userInfo) {
    switch (userInfo.role) {
      case "systemAdmin":
        roleKeys.push("systemAdmin", "cpoAdmin", "cpoMonitor", "retailer", "user");
        break;
      case "admin":
          roleKeys.push("admin", "cpoAdmin", "cpoMonitor", "retailer", "user");
          break;
      case "scAdmin":
        if (activeTenant) {
          roleKeys.push("scAdmin", "cpoAdmin", "cpoMonitor", "retailer", "user");
        } else {
          roleKeys.push("cpoAdmin", "cpoMonitor", "retailer", "user");
        }
        break;
      case "cpoAdmin":
        roleKeys.push("cpoAdmin", "cpoMonitor", "retailer", "user");
        break;
    }
  }
  const roleMap = {};
  for (const k of roleKeys) {
    roleMap[k] = roles[k];
  }
  return roleMap;
};

export default Vue.extend({
  components: { BlurredTenant },
  data() {
    return {
      showBlur: userManager.checkRole(["cpoAdmin", "cpoMonitor"], true) && !!userManager.getUserInfo().tenant?.setting?.expDate && userManager.getUserInfo().tenant.setting.expDate < new Date().getTime(),
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "username",
          "sort-desc": false,
        },
        content: {
          name: "Users",
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              tenantId: userManager.checkRole(["systemAdmin", "admin"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "Organization",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "tenants",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              orderBy: "name",
                              orderType: "asc",
                              filters: [
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              name: {
                attrs: {
                  label: "Full Name",
                },
                rules: {
                  match: {},
                },
              },
              username: {
                attrs: {
                  label: "Username",
                },
                rules: {
                  match: {},
                },
              },
              email: {
                attrs: {
                  label: "Business Email",
                },
                rules: {
                  match: {},
                },
              },
              phone: {
                attrs: {
                  label: "Business Phone",
                },
                rules: {
                  match: {},
                },
              },
              role: {
                type: "select",
                attrs: {
                  label: "Role",
                  items: makeSelectOptions(filterableRoleMap()),
                },
                rules: {
                  equal_to: {},
                },
              },
              status: {
                type: "select",
                attrs: {
                  label: "Status",
                  items: [
                    { text: "Active", value: true },
                    { text: "Inactive", value: false },
                  ],
                },
                rules: {
                  equal_to: {},
                },
              },
              disabled: {
                type: "select",
                attrs: {
                  label: "Disabled",
                  items: [
                    { text: "Disabled", value: true },
                    { text: "Enable", value: false },
                  ],
                },
                rules: {
                  equal_to: {},
                },
              },
            },
          },
          displayFields: {
            username: {
              text: "Username",
              sortable: true,
            },
            email: {
              text: "Business Email",
              sortable: true,
            },
            name: {
              text: "Full Name",
              sortable: true,
            },
            phone: {
              text: "Business Phone",
              sortable: true,
            },
            role: {
              text: "Role",
              sortable: true,
              options: {
                label(value) {
                  return roles[value];
                },
              },
            },
            status: {
              text: "Status",
              options: {
                label(value) {
                  return (value && "Active") || "InActive";
                },
                attrs(value) {
                  return {
                    color: value ? "green" : "red",
                  };
                },
              },
            },
            disabled: {
              text: "Disabled",
              options: {
                label(value) {
                  return (value && "Disabled") || "Enable";
                },
                attrs(value) {
                  return {
                    color: value ? "red" : "green",
                  };
                },
              },
            },
            createdTime: {
              text: "Created Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            updatedTime: {
              text: "Updated Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            tenant: userManager.checkRole(["systemAdmin", "admin"]) && {
              text: "Organization",
              options: {
                subProp: "tenant.name",
                label: true,
                sortBy: "tenantId",
              },
            },
            action: {
              text: "Actions",
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "email",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {},
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const payload = options.payload ? JSON.parse(options.payload) : {};
              const filters = payload.filters || [];
              if (!userManager.checkRole(["systemAdmin"])) {
                filters.push({
                    key: "role",
                    operator: "not_equal_to",
                    value: "systemAdmin",
                  });
              }
              payload.filters = filters;
              options.payload = JSON.stringify(payload);
              const { items, count } = await coreApiClient.call(
                "users",
                "findAll",
                options
              );
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call(
                "users",
                "create",
                undefined,
                item
              );
            },
            async update(item) {
              return await coreApiClient.call(
                "users",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("users", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Full Name",
                  required: true,
                },
              },
              username: {
                attrs: {
                  label: "Username",
                  required: true,
                },
              },
              email: {
                type: "email",
                attrs: {
                  label: "Business Email",
                  required: true,
                },
              },
              tenantId: userManager.checkRole(["systemAdmin", "admin"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "Organization",
                  required: false,
                  "item-value": "_id",
                  "item-text": (item) => `${item.name}`,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("tenants", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            orderBy: "name",
                            orderType: "asc",
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              phone: {
                type: "phone",
                attrs: {
                  label: "Business Phone",
                },
              },
              role: {
                type: "select",
                attrs: {
                  label: "Role",
                  required: true,
                },
                ext: {
                  makeAttrs(attrs: any = {}, self) {
                    const form = self.context();
                    if (userManager.checkRole(["systemAdmin"])) {
                      const activeTenant = appService.getActiveTenant();
                      if (!activeTenant && form.data?.value?.tenantId) {
                        attrs.items = makeSelectOptions(
                          assignableRoleMap(["cpoAdmin", "cpoMonitor", "retailer", "user"])
                        );
                      } else {
                        attrs.items = makeSelectOptions(
                          assignableRoleMap(["cpoAdmin", "cpoMonitor", "retailer", "user", "systemAdmin", "admin"])
                        );
                      }
                    }
                    if (userManager.checkRole(["admin"])) {
                      const activeTenant = appService.getActiveTenant();
                      if (!activeTenant && form.data?.value?.tenantId) {
                        attrs.items = makeSelectOptions(
                          assignableRoleMap(["cpoAdmin", "cpoMonitor", "retailer", "user"])
                        );
                      } else {
                        attrs.items = makeSelectOptions(
                          assignableRoleMap(["cpoAdmin", "cpoMonitor", "retailer", "user", "admin"])
                        );
                      }
                    }
                    if (!attrs.items) {
                      attrs.items = makeSelectOptions(assignableRoleMap());
                    }
                    return attrs;
                  },
                },
              },
              status: {
                type: "boolean",
                attrs: {
                  label: "Active",
                },
                ext: {
                  defaultValue: true,
                },
              },
              disabled: {
                type: "boolean",
                attrs: {
                  label: "Disabled",
                },
                ext: {
                  defaultValue: false,
                },
              },
            },
            insertForm:
              userManager.checkRole(["systemAdmin", "admin"]) ? {
                content: {
                  fieldNames: [
                    "name",
                    "username",
                    "password",
                    "email",
                    "phone",
                    "tenantId",
                    "role",
                    "status",
                  ],
                  fields: {
                    name: {},
                    username: {},
                    password: {
                      type: "password",
                      attrs: {
                        label: "Password",
                        required: true,
                      },
                    },
                  },
                },
              } : (userManager.checkRole(["cpoAdmin"]) && {
                content: {
                  fieldNames: [
                    "name",
                    "username",
                    "password",
                    "email",
                    "phone",
                    "role",
                    "status",
                  ],
                  fields: {
                    name: {},
                    username: {},
                    password: {
                      type: "password",
                      attrs: {
                        label: "Password",
                        required: true,
                      },
                    },
                  },
                },
              }),
            editForm: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin"]) && {
              content: {
                fieldNames: [
                  "name",
                  "username",
                  "password",
                  "email",
                  "phone",
                  "role",
                  "status",
                  "disabled",
                ],
                fields: {
                  name: {},
                  username: {},
                  password: {
                    type: "password",
                    attrs: {
                      label: "Password",
                    },
                  },
                },
              },
            },
            delete: {
              confirmDisplayField: "email",
            },
          },
        },
      },
    };
  },
});
